<template>
  <div class="container">
    <section id="contentbox" class="h-100 mb-5">
      <div class="form-login form-horizontal" id="register">
        <div class="bg-dark text-white border border-secondary rounded px-3 py-2 mt-2">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <div class="indexlogo">
                <!-- <div class="logoindex">
                  <router-link to="welcome"><img src="@/assets/wisdom/logo wisdombet-03.png" /></router-link>
                </div> -->
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-center text-sm-center text-md-right">
              <h3 class="mt-1 font-weight-light"><i class="fas fa-key"></i> ลืมรหัสผ่าน</h3>
            </div>
          </div>
        </div>
        <div class="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2">
          <h5 class="text-danger">
            <span class="badge badge-pill badge-danger font-weight-light">STEP 2</span> ระบุรหัสยืนยัน
          </h5>
          <div class="row">
            <div class="col-12 col-sm-12">
              <h6 class="text-dark"><i class="fas fa-sms"></i> โปรดนำเลข ที่ได้รับจาก sms มากรอกในช่องว่าง</h6>
            </div>
            <div class="col-12" style="display:flex;justify-contents:center;">
              <div class="col-12">
                <div class="group__keyboard">
                  <div class="num-pad box__show-number">
                    <h4 class="text-center">ระบุตัวเลข 4 หลัก</h4>
                    <div class="label-number lists d-flex justify-content-center">
                      <div id="bet_num" v-if="number.split('').length > 0">
                        <label class="number" v-for="(item, index) in type" :key="index">{{
                          number.split('')[index] ? number.split('')[index] : ' '
                        }}</label>
                      </div>
                      <div id="bet_num" v-else>
                        <label class="number" v-for="index in type" :key="index"> </label>
                      </div>
                    </div>
                    <div class="key-pad box__keyboard">
                      <div class="row p-2">
                        <div
                          class="col-3"
                          :key="index"
                          v-for="(number, index) in [
                            1,
                            2,
                            3,
                            'delete',
                            4,
                            5,
                            6,
                            'clear',
                            7,
                            8,
                            9,
                            undefined,
                            null,
                            0,
                            null,
                            undefined
                          ]"
                        >
                          <button v-if="number === 'clear'" class="btn btn-dark btn-block btn-reset" @click="clear()">
                            <span>ล้าง</span><span>ข้อมูล</span>
                          </button>
                          <button
                            v-else-if="number === 'delete'"
                            class="btn btn-danger btn-block"
                            data-id="delete"
                            @click="deleteLast()"
                          >
                            <i class="fas fa-backspace"></i>
                          </button>
                          <div v-else-if="number === undefined"></div>
                          <button
                            v-else
                            :class="number === null ? 'btn btn-blank' : 'btn btn-outline-primary btn-block'"
                            :disabled="number === null ? true : false"
                            :data-id="number"
                            @click="pushNumber(number)"
                          >
                            {{ number }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 text-center">
              <small class="text-secondary" style="display: inline-block; line-height: 1.2">
                กรุณาใส่หมายเลขที่ระบบได้ส่งไปยังเบอร์โทรศัพท์ของท่านภายใน 5 นาที
              </small>
            </div>
            <div class="col-12 border-bottom my-3"></div>
            <div class="col-7 col-sm-7 col-md-6 text-right px-0">
              <small>ส่งรหัสไปยังเบอร์</small>
              <h6 class="text-danger"><i class="fas fa-mobile-alt"></i> {{ mobilePhone }}</h6>
            </div>
            <div class="col-5 col-sm-5 col-md-6 text-left px-2">
              <button class="btn btn-outline-dark btn-sm mt-2" @click="requestAgain">
                <i class="fas fa-sync-alt"></i> ส่งอีกครั้ง
              </button>
            </div>
          </div>
        </div>

        <div class="bg-secondary border-dark shasow-sm rounded p-3 h-100 my-2 mb-5">
          <div class="row">
            <div class="col">
              <router-link to="/hotgame" tag="button" class="btn btn-dark btn-block">
                <i class="fas fa-chevron-left"></i> ย้อนกลับ
              </router-link>
            </div>
            <div class="col">
              <button @click="onSubmit" class="btn btn-primary btn-block">
                ถัดไป <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { verifyOTPPinService, submitForgetService } from '@/services'
export default {
  data () {
    return {
      type: 4,
      number: ''
    }
  },
  computed: {
    ...mapState({
      mobilePhone: state => state.userModule.mobilePhone
    })
  },
  methods: {
    ...mapActions(['setLoading', 'setProfileReset']),
    async onSubmit () {
      try {
        this.setLoading(true)
        const resp = await verifyOTPPinService({ otpRef: this.number, mobilePhone: this.mobilePhone })
        if (resp.status_code === 'SUCCESS') {
          this.setProfileReset(resp.data)
          this.$router.replace('/reset')
        } else {
          this.clear()
        }
        this.setLoading(false)
      } catch (error) {
        this.clear()
        this.setLoading(false)
      }
    },
    async requestAgain () {
      try {
        this.setLoading(true)
        window.scrollTo(0, 0)
        this.clear()
        const resp = await submitForgetService({ mobilePhone: this.mobilePhone })
        if (resp.status_code === 'SUCCESS') {
          this.setMobilePhone(this.mobilePhone)
        }
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    },
    pushNumber (numbers) {
      if (this.number.length < 4) {
        this.number = this.number + numbers
      }
    },
    clear () {
      this.number = ''
    },
    deleteLast () {
      this.number = this.number.substring(0, this.number.length - 1)
    }
  }
}
</script>

<style>
</style>
